import './offer-banner.css'
import React, { useState } from 'react'
import { cn } from 'utils'
import { Golfer } from 'const/types'
import { ToggleSaveButton } from 'components/toggle-save-button'
import { OfferingEnds } from 'ui/time-ago'
import { Link } from 'ui/link'
import { Button } from 'ui/button'
import { addInvestmentRoute } from 'const/routes'
import { ContentViewer } from 'components/content-viewer'
import moment from 'moment'
import { Dialog } from 'ui/dialog'
import { Form, SubmitButton } from 'ui/form'
import { Field } from 'ui/field'
import * as api from 'api'

export function convertTZ(date, tzString) {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    }),
  )
}

const UpdateMeDialog = ({ email, onClose, offeringUuid, showSuccessAlert }) => {
  const handleSubmit = async data => {
    const res = await api.emails.updateMe(data)
    showSuccessAlert('Your email was saved successfully.')
    onClose()
  }
  return (
    <Dialog onClose={onClose} title="Please Enter Your Email">
      <Form onSubmit={handleSubmit} className="update-me-form">
        <Field name="email" required type="email" label="Email" defaultValue={email} />
        <Field name="offeringUuid" defaultValue={offeringUuid} className="hidden-field" />
        <SubmitButton>Send</SubmitButton>
      </Form>
    </Dialog>
  )
}

export const OfferBanner = ({
  golfer: {
    uuid,
    studentImage,
    name,
    closedAt,
    endedText1,
    closedByTime,
    endedText2,
    isGoalMet,
    isMaxGoalMet,
    goalMetText,
    totalInvestmentAmount,
    minAmount,
    offeringName,
    email,
  } = {},
  showSuccessAlert,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  // const pronoun = (gender || '').toLowerCase() === 'female' ? 'her' : 'his'
  const defaultText = {
    blocks: [
      {
        type: 'paragraph',
        data: {
          className: 'mb-50',
          text: `Invest in ${offeringName} and share in his future success.`,
        },
      },
    ],
  }
  const progressLabel = Math.round((totalInvestmentAmount / minAmount) * 100)
  const closedOfferingText = `THIS OFFERING IS NOW CLOSED. ${offeringName.toUpperCase()} HAS SUCCESSFULLY RAISED ${progressLabel}% OF THEIR GOAL`
  const goalMetNotClosedText = `THIS OFFERING HAS REACHED ITS MINIMUM GOAL, BUT THERE IS STILL TIME TO INVEST IN  ${offeringName.toUpperCase()}`
  const goalMetSecondText =
    'Register your interest in future offerings by this Creator by clicking the "Update me" button above.'
  const expiredText = 'OFFERING DEADLINE HAS PASSED'

  const closedNewYorkDate = moment(convertTZ(closedAt, 'America/New_York'))
    .subtract(1, 'minute')
    .format('MMMM DD')
  const endedText = `OFFERING ENDS ON ${closedNewYorkDate}, AT 11:59PM EST`
  const offeringEndsText = closedByTime ? expiredText : endedText

  const toggleUpdateMe = () => {
    setIsOpen(prev => !prev)
  }
  return (
    <div className="offer-banner">
      <img src={studentImage} width={1200} height={800} className="main-banner-img" alt="" />
      <div className="time-left-container">
        <OfferingEnds date={offeringEndsText} />
      </div>
      <div className="container">
        <ContentViewer
          defaultContent={defaultText}
          className="ce-paragraph cdx-block"
          contentKey={`offer-details/banner${uuid}`}
        />
        <div className="col-6 offset-md-2 col-md-4 offset-lg-0 col-lg-4 toggle-con">
          {closedByTime ? (
            <Button type="primary" onClick={toggleUpdateMe}>
              Update Me
            </Button>
          ) : (
            <Link
              to={addInvestmentRoute({ uuid })}
              className={cn('btn btn-primary', closedByTime && 'disabled')}
            >
              Invest
            </Link>
          )}
          <ToggleSaveButton uuid={uuid} className="save-toggle" />
          {isOpen && (
            <UpdateMeDialog
              onClose={toggleUpdateMe}
              email={email}
              offeringUuid={uuid}
              showSuccessAlert={showSuccessAlert}
            />
          )}
        </div>
        {!closedByTime && isGoalMet && !isMaxGoalMet && (
          <p className="endedText2 cdx-block">{goalMetNotClosedText}</p>
        )}
        {(closedByTime || isMaxGoalMet) && (
          <>
            <p className="endedText2 cdx-block">{closedOfferingText}</p>
            <p className="endedText1 cdx-block">{goalMetSecondText}</p>
          </>
        )}
      </div>
    </div>
  )
}

OfferBanner.propTypes = {
  golfer: Golfer,
}
